import axios from 'axios'

export const checkMaintenanceStatus = async (token) => {
  try {
    // Check if maintenance mode is active
    const maintenanceResponse = await axios.get(import.meta.env.VITE_API_URL + '/api/users/maintenance_status/')
    
    // Get user details to check if admin
    const userResponse = await axios.get(import.meta.env.VITE_API_URL + '/api/users/me/', {
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Token ${token}`,
      }
    })

    return {
      isMaintenance: maintenanceResponse.data.is_maintenance,
      isAdmin: userResponse.data.is_staff
    }
  } catch (err) {
    console.error('Error checking maintenance status:', err)
    return { isMaintenance: false, isAdmin: false }
  }
} 